import React, { useState, useEffect } from 'react'
import pngToPDFImg from '../assets/png-to-pdf.png';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UploadCard from '../components/UploadCard';
import Loading from '../assets/Loading'
import { useNavigate } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';
import ReactGA from 'react-ga4';


async function createPdfFromImage(imageFile) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    const imgBytes = await imageFile.arrayBuffer();
    const img = await pdfDoc.embedPng(imgBytes);

    const { width, height } = img.scale(1);
    page.setSize(width, height);
    page.drawImage(img, {
        x: 0,
        y: 0,
        width: width,
        height: height,
    });

    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
}

function ImageToPDFUploadComponent() {

    const MAX_FILES = 1;
    const MAX_SIZE = 100 * 1024 * 1024; // 100 MB

    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const navigate = useNavigate();


    function handleImageFileChange(event) {
        const img = event.target.files;

        if (img.length > MAX_FILES) {
            setError(`You can upload up to ${MAX_FILES} files at a time.`);
            return;
        }

        if (img[0].size > MAX_SIZE) {
            setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
            return;
        }

        setImageFile(img[0]);
    }

    async function handleImageToPDFSubmit(event) {
        event.preventDefault();
        try {
            setUploading(true);
            const ImageToPDFBlob = await createPdfFromImage(imageFile);
            const url = window.URL.createObjectURL(ImageToPDFBlob);

            ReactGA.event({
                category: 'User',
                action: 'PNG To PDF Converted'
              });
            
            const defaultFilename = imageFile.name + ' PDF-Quick-Fix.pdf';
            navigate('/download-file', { state: { link: url, defaultFilename: defaultFilename } });
        } catch (error) {
            console.error('Error Converting PDF:', error);
            setError('An error occurred while Converting PDF. Please try again.');
        } finally {
            setUploading(false);  // Optionally, reset the uploading state
        }

    }

    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            {uploading && (
                <div>
                    <Loading />
                </div>
            )}
            <form onSubmit={handleImageToPDFSubmit} className="rounded w-100">
                <div className="form-group text-center">
                    <div className="custom-file mx-auto mb-3">
                        <input
                            className="custom-file-input"
                            type="file"
                            id="imageFile"
                            name="imageFile"
                            accept="image/png, image/jpeg"
                            onChange={handleImageFileChange}
                            required
                            style={{ cursor: 'pointer' }}
                        />
                        <label className="custom-file-label" htmlFor="imageFile" style={{ cursor: 'pointer' }}>
                            Choose Stamp Image (PNG/JPEG)
                        </label>
                        {imageFile && (
                            <p>{imageFile.name}</p>
                        )}
                    </div>
                </div>
                <div>
                    <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
                        {uploading ? 'Converting...' : 'Convert'}
                    </button>
                </div>
            </form>
        </div>
    );
}


function ImageToPDF() {
    const imgToPdfTool = { name: 'PNG to PDF', desc: 'Convert your PNG images into PDF format effortlessly with the PNG to PDF tool. This is perfect for creating PDF documents from your image files.', 
        img: pngToPDFImg }

        useEffect(() => {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
          }, []);

    return (
        <div className="App d-flex flex-column min-vh-100">
            <Navbar />
            <UploadCard toolImage={imgToPdfTool.img} toolTitle={imgToPdfTool.name} toolDescription={imgToPdfTool.desc} UploadComponent={ImageToPDFUploadComponent} />
            <Footer />
        </div>
    )
}

export default ImageToPDF