import React,{ useEffect} from 'react'
import { Container, Card } from 'react-bootstrap';
import successImage from '../assets/success-svgrepo-com.svg'
import DownloadButton from '../assets/DownloadButton';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import ReactGA from 'react-ga4';



function DownloadFile() {
    const location = useLocation();
    const { link, defaultFilename } = location.state || {}; // Get the download link and filename from state

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    function handleClick(event) {
        if (defaultFilename && defaultFilename.includes('split')) {
            event.preventDefault(); // Prevent the default anchor behavior
            saveAs(link, defaultFilename); // Use saveAs to download the blob
        }
        // For other cases, let the anchor tag handle the download
    }

    return (
        <div className="d-flex flex-column min-vh-100">
        <Navbar />
        <Container className="d-flex justify-content-center align-items-center main-container">
            <Card className="text-center shadow-lg card-custom">
                <Card.Img variant="top" src={successImage} className="card-image" style={{ width: "200px", height: "200px", objectFit: 'contain' }} />
                <Card.Body className="card-body-custom">
                    <Card.Title>Your File is Ready to Download.</Card.Title>
                    <Card.Text>
                        Just wanted to say a huge thank you for your patronage. It has been a pleasure serving you.
                    </Card.Text>
                    {link ? (
                        <a href={link} download={defaultFilename} onClick={handleClick}>
                            <DownloadButton />
                        </a>
                    ) : (
                        <p>No file available for download</p>
                    )}
                </Card.Body>
            </Card>
        </Container>
        <Footer />
    </div>
    )
}

export default DownloadFile