import React from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const TermsOfService = () => {
    return (
        <div>
            <Navbar />
        <div className="container mt-5">
            <h1 className="text-center mb-4">Terms of Service</h1>

            <section className="mb-4">
                <h2 className="h4">Introduction</h2>
                <p>Welcome to <strong>PDF Quick Fix</strong>. By using our website and services, you agree to comply with and be bound by the following terms and conditions.</p>
            </section>

            <section className="mb-4">
                <h2 className="h4">Services</h2>
                <p>Our website offers various PDF tools including but not limited to merging, splitting, converting, and adding watermarks or stamps to PDFs.</p>
            </section>

            <section className="mb-4">
                <h2 className="h4">Use of Services</h2>
                <ul className="list-unstyled">
                    <li><strong>No Registration Required:</strong> You can access and use our tools without the need for registration.</li>
                    <li><strong>Free of Charge:</strong> Currently, all our services are provided for free.</li>
                    <li><strong>Immediate File Deletion:</strong> All uploaded files are deleted immediately after processing. We do not store any files.</li>
                </ul>
            </section>

            <section className="mb-4">
                <h2 className="h4">User Conduct</h2>
                <p>You agree to use our services responsibly and not for any unlawful purposes. Misuse of our tools may result in termination of access.</p>
            </section>

            <section className="mb-4">
                <h2 className="h4">Limitation of Liability</h2>
                <p>Our services are provided "as is" without any warranties. We are not liable for any damages arising from the use or inability to use our services.</p>
            </section>

            <section className="mb-4">
                <h2 className="h4">Changes to These Terms</h2>
                <p>We may update these Terms of Service periodically. Any changes will be reflected on this page, so please review it frequently.</p>
            </section>

            <section>
                <h2 className="h4">Contact Us</h2>
                <p>For any questions or concerns regarding these Terms of Service, please <strong><a href='/contact'>Contact Us</a></strong>.</p>
            </section>
        </div>
        <Footer />
        </div>
    );
}

export default TermsOfService;
