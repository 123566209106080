import React, { useState, useEffect } from 'react'
import addStampImg from '../assets/Add-Stamp.png';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UploadCard from '../components/UploadCard';
import Loading from '../assets/Loading'
import { useNavigate } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';
import ReactGA from 'react-ga4';


async function createPdfFromImage(imageFile, pdfDimensions) {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([pdfDimensions.width, pdfDimensions.height]);

  const imgBytes = await imageFile.arrayBuffer();
  const img = await pdfDoc.embedPng(imgBytes);

  let { width: imgWidth, height: imgHeight } = img.scale(1);

  // Scale the image to fit within the PDF dimensions if necessary
  const scale = Math.min(pdfDimensions.width / imgWidth, pdfDimensions.height / imgHeight);
  imgWidth *= scale;
  imgHeight *= scale;

  const x = (pdfDimensions.width - imgWidth) / 2;
  const y = (pdfDimensions.height - imgHeight) / 2;

  page.drawImage(img, {
    x,
    y,
    width: imgWidth,
    height: imgHeight,
  });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
}

async function getPdfDimensions(pdfFile) {
  const pdfBytes = await pdfFile.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const firstPage = pdfDoc.getPage(0);
  const { width, height } = firstPage.getSize();
  return { width, height };
}


function StampUploadComponent() {
  const MAX_FILES = 1;
  const MAX_SIZE = 100 * 1024 * 1024; // 100 MB

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let totalSize = files.reduce((sum, file) => sum + file.size, 0);
    const isAllPdf = files.every(file => file.type === 'application/pdf');

    if (!isAllPdf) {
      setError('All selected files must be PDF.');
      return;
    }

    if (files.length > MAX_FILES) {
      setError(`You can upload up to ${MAX_FILES} files at a time.`);
      return;
    }

    if (totalSize > MAX_SIZE) {
      setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    setSelectedFiles(files[0]);
    setError('');
  };


  function handleImageFileChange(event) {
    const img = event.target.files;
    setImageFile(img[0]);
  }


  async function handleStampSubmit(event) {
    event.preventDefault();  // Prevent default form submission
    try {
      setUploading(true)
      const pdfDimensions = await getPdfDimensions(selectedFiles);
      const imagePdf = await createPdfFromImage(imageFile, pdfDimensions);

      // Convert the Blob to a File
      const imagePdfFile = new File([imagePdf], 'image.pdf', { type: 'application/pdf' });

      const formData = new FormData();
      formData.append('contentFile', selectedFiles);
      formData.append('imageFile', imagePdfFile);

      const response = await axios.post('/pdf/stamp', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      const stampData = response.data.stamp;
      const stampedPdfBlob = new Blob([new Uint8Array(stampData.split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(stampedPdfBlob);

      ReactGA.event({
        category: 'User',
        action: 'Stamped PDF'
      });

      const defaultFilename = 'PDF-Quick_Fix Stamped.pdf';
      navigate('/download-file', { state: { link: url, defaultFilename: defaultFilename } });
    } catch (error) {
      console.error('Error Stamping PDFs:', error);
      setError('An error occurred while Stamping PDFs. Please try again.');
    } finally {
      setUploading(false);  // Optionally, reset the uploading state
    }

  }

  return (
    <div>
      {error && <p className="text-danger text-center">{error}</p>}
      {uploading && (
        <div>
          <Loading />
        </div>
      )}
      <form onSubmit={handleStampSubmit} className="rounded w-100">
        <div className="form-group text-center">
          <div className="custom-file mx-auto mb-3">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={handleFileChange}
              accept="application/pdf"
              required
              style={{ cursor: 'pointer' }}
            />
            <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
              {selectedFiles ? 'File/Files Selected' : 'Choose PDF File'}
            </label>
            {selectedFiles && (
              <div>
                <p className="scrolling-text">
                  {selectedFiles.name}
                </p>
              </div>
            )}
          </div>
          <div className="custom-file mx-auto mb-3">
            <input
              className="custom-file-input"
              type="file"
              id="imageFile"
              name="imageFile"
              accept="image/png"
              onChange={handleImageFileChange}
              required
              style={{ cursor: 'pointer' }}
            />
            <label className="custom-file-label" htmlFor="imageFile" style={{ cursor: 'pointer' }}>
              Choose Stamp Image (PNG)
            </label>
            {imageFile && (
              <p>{imageFile.name}</p>
            )}
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
            {uploading ? 'Adding Stamp...' : 'Add Stamp'}
          </button>
        </div>
      </form>
    </div>
  );


}

function StampTool() {
  const stampTool = { name: 'Add a Stamp on all Pages', desc: 'Add an image as an overlay stamp to every page of your PDF with the Stamp PDF tool. This feature is useful for adding approval marks, logos, or other important indicators.', 
    img: addStampImg }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);
    
  return (
    <div className="App d-flex flex-column min-vh-100">
      <Navbar />
      <UploadCard toolImage={stampTool.img} toolTitle={stampTool.name} toolDescription={stampTool.desc} UploadComponent={StampUploadComponent} />
      <Footer />
    </div>
  )
}

export default StampTool