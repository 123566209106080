import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UploadCard from '../components/UploadCard';
import Loading from '../assets/Loading'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import pdfToDocImg from '../assets/pdf-to-doc.png';
import ReactGA from 'react-ga4';


function PDFToDocsUploadComponent() {
    const MAX_FILES = 1;
    const MAX_SIZE = 100 * 1024 * 1024; // 100 MB

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        let totalSize = files.reduce((sum, file) => sum + file.size, 0);
        const isAllPdf = files.every(file => file.type === 'application/pdf');

        if (!isAllPdf) {
            setError('All selected files must be PDF.');
            return;
        }

        if (files.length > MAX_FILES) {
            setError(`You can upload up to ${MAX_FILES} files at a time.`);
            return;
        }

        if (totalSize > MAX_SIZE) {
            setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
            return;
        }

        setSelectedFiles(files[0]);
        setError('');
    };


    async function handleConvertSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('file', selectedFiles);

        setUploading(true);
        try {
            const response = await axios.post('/pdf/converttoword', formData, {
                responseType: 'blob',
            });

            // Create a URL for the converted file
            const url = window.URL.createObjectURL(new Blob([response.data]));

            ReactGA.event({
                category: 'User',
                action: 'PDF to Document Converted'
              });

            const defaultFilename = 'Converted ' + selectedFiles.name + '.docx';
            navigate('/download-file', { state: { link: url, defaultFilename: defaultFilename } });
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Failed to convert the file.')
        } finally {
            setUploading(false);
        }
    }


    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            {(uploading) && (
                <div>
                    <Loading />
                </div>
            )}
            <form onSubmit={handleConvertSubmit} className="rounded w-100">
                <div className="form-group text-center">
                    <div className="custom-file mx-auto">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={handleFileChange}
                            accept="application/pdf"
                            multiple
                            required
                            style={{ cursor: 'pointer' }}
                        />
                        <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
                            {selectedFiles ? 'File/Files Selected' : 'Choose File'}
                        </label>
                        {selectedFiles && (
                            <div>
                                <p className="scrolling-text">
                                    {selectedFiles.name}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
                        {uploading ? 'Converting...' : 'Convert'}
                    </button>
                </div>
            </form>
        </div>
    );
}


function PDFToDocs() {
    const pdfToDocTool = { name: 'PDF To Docs', desc: 'The PDF to Doc tool converts your PDF files into editable document formats, such as Word. This makes it easy to edit and repurpose the content of your PDFs.',
        img: pdfToDocImg }

        useEffect(() => {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
          }, []);

    return (
        <div className="App d-flex flex-column min-vh-100">
            <Navbar />
            <UploadCard toolImage={pdfToDocTool.img} toolTitle={pdfToDocTool.name} toolDescription={pdfToDocTool.desc} UploadComponent={PDFToDocsUploadComponent} />
            <Footer />
        </div>
    )
}

export default PDFToDocs