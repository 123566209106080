import React, { useState, useEffect } from 'react'
import compressPDFImg from '../assets/Compress-PDF.png';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UploadCard from '../components/UploadCard';
import Loading from '../assets/Loading'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';



function CompressUploadComponent() {
  const MAX_FILES = 1;
  const MAX_SIZE = 100 * 1024 * 1024; // 100 MB

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let totalSize = files.reduce((sum, file) => sum + file.size, 0);
    const isAllPdf = files.every(file => file.type === 'application/pdf');

    if (!isAllPdf) {
      setError('All selected files must be PDF.');
      return;
    }

    if (files.length > MAX_FILES) {
      setError(`You can upload up to ${MAX_FILES} files at a time.`);
      return;
    }

    if (totalSize > MAX_SIZE) {
      setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    setSelectedFiles(files);
    setError('');
  };

  async function handleCompressSubmit(event) {
    event.preventDefault();
    setUploading(true);
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('pdfFiles', file);
    });
    try {
      const response = await axios.post('/pdf/compress', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const compressData = response.data.compress;
      const compressBlob = new Blob([new Uint8Array(compressData.split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
      const compressPDFDownloadLink = window.URL.createObjectURL(compressBlob);
      setUploading(false);

      ReactGA.event({
        category: 'User',
        action: 'PDF Compression'
      });

      const defaultFilename = 'Compressed '+selectedFiles[0].name;
      navigate('/download-file', { state: { link: compressPDFDownloadLink, defaultFilename: defaultFilename } });

    }
    catch (error) {
      console.error('Failed to upload PDF', error);
      setError('Failed to upload PDF');
      setUploading(false);
  }
  }

  return (
    <div>
      {error && <p className="text-danger text-center">{error}</p>}
      {(uploading) && (
        <div>
          <Loading />
        </div>
      )}
      <form onSubmit={handleCompressSubmit} className="rounded w-100">
        <div className="form-group text-center">
          <div className="custom-file mx-auto">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={handleFileChange}
              accept="application/pdf"
              multiple
              required
              style={{ cursor: 'pointer' }}
            />
            <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
              {selectedFiles.length > 0 ? 'File/Files Selected' : 'Choose File'}
            </label>
            {selectedFiles.length > 0 && (
              <div>
                <p className="scrolling-text">
                  {selectedFiles.map(file => file.name).join(', ')}
                </p>
              </div>
            )}
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }}>
            Compress PDF
          </button>
        </div>
      </form>
    </div>
  );
}


function CompressTool() {
  const compressTool = { name: 'Compress PDF', desc: 'Compress your PDF files to reduce their size without compromising on quality. This tool helps you save storage space and makes sharing large documents easier and faster.', 
    img: compressPDFImg }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);
    
  return (
    <div className="App d-flex flex-column min-vh-100">
      <Navbar />
      <UploadCard toolImage={compressTool.img} toolTitle={compressTool.name} toolDescription={compressTool.desc} UploadComponent={CompressUploadComponent} />
      <Footer />
    </div>
  )
}

export default CompressTool