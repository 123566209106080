import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";


function Contact() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [reCaptcha, setReCaptcha] = useState(null)

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: 'User',
      action: 'Contact Form Submitted'
    });

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setSuccess('Your message has been sent successfully!');
          console.log(result.text);
        },
        (error) => {
          setError(error.text);
          console.log(error.text);
        }
      );
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <div className="container flex-grow-1 mt-5">
        <h2>Contact Us</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <form ref={form} onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <ReCAPTCHA
            sitekey="6LcDzg4qAAAAAKIVihIs2y_3nS7aSgibEnp6-5t8"
            onChange={(val) => setReCaptcha(val)}
          />,
          <button type="submit" className="btn btn-primary mt-3 mb-3" disabled={!reCaptcha}>Submit</button>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
