import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


function About() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const websiteName = "PDF Quick Fix";
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h1 className="text-center mb-4">About Us</h1>
          
          <h2 className="mb-3">Welcome to {websiteName}</h2>
          <p>
            At {websiteName}, we are dedicated to providing the best PDF tools to make your document management easier and more efficient. Our mission is to offer a comprehensive suite of tools that cater to all your PDF needs, whether you are merging, splitting, compressing, or converting documents.
          </p>
          <p>
            We understand that handling PDFs can sometimes be a hassle, which is why we've developed tools that are both user-friendly and powerful, designed to save you time and effort.
          </p>

          <h3 className="mt-4">Our Tools</h3>
          <p>We offer a variety of tools to help you manage your PDFs:</p>
          <ul>
            <li><strong>Flipkart Cropper:</strong> Crop Flipkart labels and invoices, and group them according to SKU.</li>
            <li><strong>Merge PDF:</strong> Merge PDFs with drag-and-drop functionality to arrange and combine files.</li>
            <li><strong>Split PDF:</strong> Split all pages or a specific range of pages from a PDF.</li>
            <li><strong>ReOrder PDF:</strong> Rearrange all pages of a PDF using drag-and-drop.</li>
            <li><strong>Compress PDF:</strong> Reduce the size of your PDF files without compromising quality.</li>
            <li><strong>Watermark PDF:</strong> Add an image as a watermark (underlay) over all pages of a PDF.</li>
            <li><strong>Stamp PDF:</strong> Add an image as a stamp (overlay) over all pages of a PDF.</li>
            <li><strong>PNG to PDF:</strong> Convert PNG images to PDF format.</li>
            <li><strong>PDF to Doc:</strong> Convert PDF files to document formats.</li>
          </ul>

          <h3 className="mt-4">Our Mission</h3>
          <p>
            Our mission is to simplify document management for individuals and businesses alike. We strive to provide tools that address the challenges of handling PDFs efficiently and effectively. Our goal is to make sure that whether you need to convert, merge, split, or compress PDFs, {websiteName} is your go-to solution.
          </p>

          <h3 className="mt-4">Why Choose Us?</h3>
          <p>
            We pride ourselves on offering user-friendly, reliable, and secure tools. Our platform is designed with you in mind, ensuring that you can manage your documents with ease. Here are a few reasons to choose {websiteName}:
          </p>
          <ul>
            <li><strong>Ease of Use:</strong> Our tools are intuitive and easy to use, even for those who are not tech-savvy.</li>
            <li><strong>Reliability:</strong> We ensure that our tools are reliable and perform consistently.</li>
            <li><strong>Security:</strong> We prioritize your privacy and security, ensuring your documents are handled safely.</li>
            <li><strong>Continuous Improvement:</strong> We are constantly updating our tools and adding new features to meet the evolving needs of our users.</li>
          </ul>

          <h3 className="mt-4">Our Team</h3>
          <p>
            Our team is comprised of experienced professionals who are passionate about technology and helping others. We work tirelessly to ensure that {websiteName} provides the best possible service and tools to our users. We believe in innovation, quality, and customer satisfaction.
          </p>

          <h3 className="mt-4">Contact Us</h3>
          <p>
            We love hearing from our users. If you have any questions, feedback, or suggestions, please feel free to contact us. Your input helps us improve and continue to provide the best service possible.
          </p>
          <p className="text-center mt-4">
            <strong>Thank you for choosing {websiteName}!</strong>
          </p>
        </div>
      </div>
    </div>

      <Footer />
    </div>
  );
}

export default About;
