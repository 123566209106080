import React from 'react';



const Footer = () => {
    const getCurrentYear = () => {
        return new Date().getFullYear();
      };
    return (
            <footer className="py-3 mt-auto bg-dark">
                <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                    <li className="nav-item"><a href="/about" className="nav-link px-2 text-white">About</a></li>
                    <li className="nav-item"><a href="/contact" className="nav-link px-2 text-white">Contact Us</a></li>
                    <li className="nav-item"><a href="/terms-of-service" className="nav-link px-2 text-white">Terms of Service</a></li>
                    <li className="nav-item"><a href="/privacy-policy" className="nav-link px-2 text-white">Privacy Policy</a></li>
                </ul>
                <p className="text-center text-white">© {getCurrentYear()} PDF Quick Fix</p>
            </footer>




        // <footer className="footer mt-auto py-3 bg-dark">
        //     <div className="container text-center">
        //         <span className="text-white">© 2024 PDF Quick Fix, All Rights Reserved</span>
        //     </div>
        // </footer>
    );
};

export default Footer;
