import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Loading from '../assets/Loading';
import UploadCard from '../components/UploadCard';
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, pointerWithin, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import DnDReOrderPDFCard from '../components/DnDReOrderPDFCard';
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import reArrangePDFImg from '../assets/rearrange-pdf-pages.png'
import { PDFDocument } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';




function ReOrderPDFUploadComponent() {

    const MAX_FILES = 1;
    const MAX_SIZE = 100 * 1024 * 1024; // 100 MB


    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagesWithId, setPagesWithId] = useState([]);
    const [activeId, setActiveId] = useState(null);

    const navigate = useNavigate();


    async function handleFileChange(event) {
        const files = Array.from(event.target.files);
        let totalSize = files.reduce((sum, file) => sum + file.size, 0);

        if (files.length > MAX_FILES) {
            setError(`You can upload up to ${MAX_FILES} files at a time.`);
            return;
        }

        if (totalSize > MAX_SIZE) {
            setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
            return;
        }
        setSelectedFiles(files[0]);
        setError('')

    }

    async function handlePageLoad(event) {
        event.preventDefault();
        setLoading(true);


        const fileReader = new FileReader();
        fileReader.onload = async () => {
            try {
                const pdfData = new Uint8Array(fileReader.result);
                const loadingTask = pdfjsLib.getDocument({ data: pdfData });
                const pdf = await loadingTask.promise;
                const pagesArray = [];
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const pageData = { id: uuidv4(), page: page, pageNumber: i }
                    pagesArray.push(pageData);
                }
                setPagesWithId(pagesArray);
            } catch (error) {
                console.error('Error loading PDF:', error);
            }
        }
        fileReader.readAsArrayBuffer(selectedFiles);

        setLoading(false);

    }


    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    };


    const handleDragEnd = (event) => {
        const { active, over } = event;
        setActiveId(null);

        if (active.id !== over.id) {
            const oldIndex = pagesWithId.findIndex(item => item.id === active.id);
            const newIndex = pagesWithId.findIndex(item => item.id === over.id);
            setPagesWithId(arrayMove(pagesWithId, oldIndex, newIndex));
        }
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    )

    async function handleReOrderSubmit(event) {
        event.preventDefault();
        console.log('handleReOrderSubmit Called');
        setUploading(true);

        try {
            const originalPdfBytes = await selectedFiles.arrayBuffer();
            const originalPdfDoc = await PDFDocument.load(originalPdfBytes);
            const reorderedPdfDoc = await PDFDocument.create();

            for (let i = 0; i < pagesWithId.length; i++) {
                const pageIndex = pagesWithId[i].pageNumber - 1;
                const [copiedPage] = await reorderedPdfDoc.copyPages(originalPdfDoc, [pageIndex]);
                reorderedPdfDoc.addPage(copiedPage);
            }

            const reorderedPdfBytes = await reorderedPdfDoc.save();
            const blob = new Blob([reorderedPdfBytes], { type: 'application/pdf' });
            const reOrderedPDFDownloadLink = window.URL.createObjectURL(blob);

            ReactGA.event({
                category: 'User',
                action: 'PDF Reordered'
              });

            const defaultFilename = 'PDF Quick Fix ReOrdered ' + selectedFiles.name;
            navigate('/download-file', { state: { link: reOrderedPDFDownloadLink, defaultFilename: defaultFilename } });
        } catch (error) {
            console.error('Error reordering PDF:', error);
            setError('Failed to reorder PDF. Please try again.');
        }

        setUploading(false);
    }


    const activeItem = pagesWithId.find((item) => item.id === activeId);

    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            {(uploading) && (
                <div>
                    <Loading />
                </div>
            )}
            <form onSubmit={handleReOrderSubmit} className="rounded w-100">
                <div className="form-group text-center">
                    <div className="custom-file mx-auto">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={handleFileChange}
                            accept="application/pdf"
                            required
                            style={{ cursor: 'pointer' }}
                        />
                        <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
                            {selectedFiles ? 'File/Files Selected' : 'Choose File'}
                        </label>
                        {selectedFiles && (
                            <div>
                                <p className="scrolling-text">
                                    {selectedFiles.name}
                                </p>
                            </div>
                        )}
                    </div>
                    {pagesWithId.length > 0 && (
                        <DndContext
                            collisionDetection={pointerWithin}
                            sensors={sensors}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                        >
                            <div className="d-flex justify-content-center mt-3 flex-wrap">
                                <SortableContext items={pagesWithId} strategy={horizontalListSortingStrategy}>
                                    {pagesWithId.map(({ id, page, pageNumber }) => (
                                        <div key={id} className="m-2">
                                            <DnDReOrderPDFCard id={id} page={page} pageNumber={pageNumber} />
                                        </div>
                                    ))}
                                </SortableContext>
                            </div>
                            <DragOverlay modifiers={[snapCenterToCursor]} dropAnimation={null} className="drag-overlay">
                                {activeItem ? <DnDReOrderPDFCard page={activeItem.page} id={activeItem.id} /> : null}
                            </DragOverlay>
                        </DndContext>
                    )}
                </div>
                <div>
                    <button onClick={handlePageLoad} type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={loading}>
                        {loading ? 'Loading...' : 'Load Pages'}
                    </button>
                    <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
                        {uploading ? 'Arranging...' : 'Re-Order PDF'}
                    </button>
                </div>
            </form>
        </div>
    );


}




function ReOrderPDF() {
    const reOrderPDFTool = {
        name: 'Re-Order PDF Pages', desc: 'Rearrange the pages of your PDF documents with our Reorder PDF tool. Simply drag and drop pages to your desired order and save the rearranged document.',
        img: reArrangePDFImg
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    return (
        <div className="App d-flex flex-column min-vh-100">
            <Navbar />
            <UploadCard toolImage={reOrderPDFTool.img} toolTitle={reOrderPDFTool.name} toolDescription={reOrderPDFTool.desc} UploadComponent={ReOrderPDFUploadComponent} />
            <Footer />
        </div>
    )
}

export default ReOrderPDF