import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Card from '../components/Card'
import mergePDFImg from '../assets/Merge-PDF.png'
import splitPDFImg from '../assets/Split-PDF.png'
import flipkartLabelCropper from '../assets/Flipkart-Label-Cropper.png'
import compressPDFImg from '../assets/Compress-PDF.png';  
import addStampImg from '../assets/Add-Stamp.png';
import addWatermarkImg from '../assets/Add-Watermark.png';
import pngToPDFImg from '../assets/png-to-pdf.png';
import reArrangePDFImg from '../assets/rearrange-pdf-pages.png'
import pdfToDocImg from '../assets/pdf-to-doc.png';
import ReactGA from 'react-ga4';






function Home() {
    const cards = [
        { name: 'Flipkart Label Cropper', desc: 'Crop and organize Flipkart labels and invoices by SKU.', img: flipkartLabelCropper, route: '/flipkart-cropper' },
        { name: 'Merge PDF', desc: 'Merge PDFs with drag-and-drop functionality.', img: mergePDFImg , route: '/mergepdf'},
        { name: 'Split PDF', desc: 'Split PDF by pages or range.', img: splitPDFImg, route: '/splitpdf' },
        { name: 'Compress PDF', desc: 'Reduce PDF file size.', img: compressPDFImg, route: '/compresspdf' },
        { name: 'Reorder PDF', desc: 'Rearrange PDF pages using drag-and-drop.', img: reArrangePDFImg, route: '/reorder-pdf' },
        { name: 'Add a Stamp on all Pages', desc: 'Overlay an image as a stamp on your PDFs.', img: addStampImg, route: '/stamppdf' },
        { name: 'Add a Watermark on All Pages', desc: 'Add a watermark image to your PDFs.', img: addWatermarkImg, route: '/watermarkpdf' },
        { name: 'PNG To PDF', desc: 'Convert PNG images to PDF.', img: pngToPDFImg, route: '/png-to-pdf' },
        { name: 'PDF To Docs', desc: 'Convert PDFs to document files.', img: pdfToDocImg, route: '/pdf-to-docx' },
    ];

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar />
            <div className="container my-5 flex-grow-1">
                <div className="row justify-content-center">
                    {cards.map((card, index) => (
                        <div key={index} className="col-lg-4 col-md-6 d-flex justify-content-center mb-4">
                            <Link to={card.route}>
                            <Card 
                                name={card.name} 
                                desc={card.desc} 
                                img={card.img} 
                            />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home