import React, { useState, useEffect } from 'react'
import addWatermarkImg from '../assets/Add-Watermark.png';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UploadCard from '../components/UploadCard';
import Loading from '../assets/Loading'
import { useNavigate } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';
import ReactGA from 'react-ga4';


async function createPdfFromImage(imageFile, pdfDimensions) {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([pdfDimensions.width, pdfDimensions.height]);

  const imgBytes = await imageFile.arrayBuffer();
  const img = await pdfDoc.embedPng(imgBytes);

  let { width: imgWidth, height: imgHeight } = img.scale(1);

  // Scale the image to fit within the PDF dimensions if necessary
  const scale = Math.min(pdfDimensions.width / imgWidth, pdfDimensions.height / imgHeight);
  imgWidth *= scale;
  imgHeight *= scale;

  const x = (pdfDimensions.width - imgWidth) / 2;
  const y = (pdfDimensions.height - imgHeight) / 2;

  page.drawImage(img, {
    x,
    y,
    width: imgWidth,
    height: imgHeight,
  });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
}

async function getPdfDimensions(pdfFile) {
  const pdfBytes = await pdfFile.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const firstPage = pdfDoc.getPage(0);
  const { width, height } = firstPage.getSize();
  return { width, height };
}


function WatermarkUploadComponent() {
  const MAX_FILES = 1;
  const MAX_SIZE = 100 * 1024 * 1024; // 100 MB

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const navigate = useNavigate();


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let totalSize = files.reduce((sum, file) => sum + file.size, 0);
    const isAllPdf = files.every(file => file.type === 'application/pdf');

    if (!isAllPdf) {
      setError('All selected files must be PDF.');
      return;
    }

    if (files.length > MAX_FILES) {
      setError(`You can upload up to ${MAX_FILES} files at a time.`);
      return;
    }

    if (totalSize > MAX_SIZE) {
      setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    setSelectedFiles(files[0]);
    setError('');
  };


  function handleImageFileChange(event) {
    const img = event.target.files;
    setImageFile(img[0]);
  }


  async function handleWatermarkSubmit(event) {
    event.preventDefault();  // Prevent default form submission
    try {
      setUploading(true)
      const pdfDimensions = await getPdfDimensions(selectedFiles);
      const imagePdf = await createPdfFromImage(imageFile, pdfDimensions);

      // Convert the Blob to a File
      const imagePdfFile = new File([imagePdf], 'image.pdf', { type: 'application/pdf' });

      const formData = new FormData();
      formData.append('contentFile', selectedFiles);
      formData.append('imageFile', imagePdfFile);

      const response = await axios.post('/pdf/watermark', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      const watermarkData = response.data.watermark;
      const watermarkedPdfBlob = new Blob([new Uint8Array(watermarkData.split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(watermarkedPdfBlob);

      ReactGA.event({
        category: 'User',
        action: 'Watermarked PDF'
      });

      const defaultFilename = 'PDF-Quick_Fix Watermarked.pdf';

      navigate('/download-file', { state: { link: url, defaultFilename: defaultFilename } });
    } catch (error) {
      console.error('Error Watermarking PDFs:', error);
      setError('An error occurred while Watermarking PDFs. Please try again.');
    } finally {
      setUploading(false);  // Optionally, reset the uploading state
    }

  }

  return (
    <div>
      {error && <p className="text-danger text-center">{error}</p>}
      {uploading && (
        <div>
          <Loading />
        </div>
      )}
      <form onSubmit={handleWatermarkSubmit} className="rounded w-100">
        <div className="form-group text-center">
          <div className="custom-file mx-auto mb-3">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={handleFileChange}
              accept="application/pdf"
              required
              style={{ cursor: 'pointer' }}
            />
            <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
              {selectedFiles ? 'File/Files Selected' : 'Choose PDF File'}
            </label>
            {selectedFiles && (
              <div>
                <p className="scrolling-text">
                  {selectedFiles.name}
                </p>
              </div>
            )}
          </div>
          <div className="custom-file mx-auto mb-3">
            <input
              className="custom-file-input"
              type="file"
              id="imageFile"
              name="imageFile"
              accept="image/png"
              onChange={handleImageFileChange}
              required
              style={{ cursor: 'pointer' }}
            />
            <label className="custom-file-label" htmlFor="imageFile" style={{ cursor: 'pointer' }}>
              Choose Stamp Image (PNG)
            </label>
            {imageFile && (
              <p>{imageFile.name}</p>
            )}
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
            {uploading ? 'Adding Watermark...' : 'Add Watermark'}
          </button>
        </div>
      </form>
    </div>
  );


}


function WatermarkTool() {
  const watermarkTool = { name: 'Add a Watermark on All Pages', desc: 'The Watermark PDF tool lets you add an image as an underlay watermark across all pages of your PDF. This is ideal for branding, security, or personalizing your documents.', 
    img: addWatermarkImg }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Navbar />
      <UploadCard toolImage={watermarkTool.img} toolTitle={watermarkTool.name} toolDescription={watermarkTool.desc} UploadComponent={WatermarkUploadComponent} />
      <Footer />
    </div>
  )
}

export default WatermarkTool