import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Home from './screens/Home';
import About from './screens/About';
import Contact from './screens/Contact';
import MergeTool from './screens/MergeTool';
import DownloadFile from './screens/DownloadFile';
import FlipkartCropper from './screens/FlipkartCropper';
import SplitTool from './screens/SplitTool';
import StampTool from './screens/StampTool';
import CompressTool from './screens/CompressTool';
import WatermarkTool from './screens/WatermarkTool';
import ImageToPDF from './screens/ImageToPDF';
import PDFToDocs from './screens/PDFToDocs';
import ReOrderPDF from './screens/ReOrderPDF';
import TermsOfService from './screens/TermsOfService';
import PrivacyPolicy from './screens/PrivacyPolicy';



function App() {
  ReactGA.initialize('G-52T74MXKQ3');
  return (
    <Router >
      <div>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/terms-of-service' element={<TermsOfService />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/flipkart-cropper' element={<FlipkartCropper />} />
          <Route exact path='/mergepdf' element={<MergeTool />} />
          <Route exact path='/splitpdf' element={<SplitTool />} />
          <Route exact path='/compresspdf' element={<CompressTool />} />
          <Route exact path='/watermarkpdf' element={<WatermarkTool />} />
          <Route exact path='/stamppdf' element={<StampTool />} />
          <Route exact path='/png-to-pdf' element={<ImageToPDF />} />
          <Route exact path='/pdf-to-docx' element={<PDFToDocs />} />
          <Route exact path='/reorder-pdf' element={<ReOrderPDF />} />
          <Route exact path='/download-file' element={<DownloadFile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
