import React from 'react'
import '../components/Card.css'

function Card({ name, desc, img }) {
    return (
        <div className="card mt-3 mb-3" style={{ "width": "18rem", "maxHeight": "360px" }}>
            <img src={img} alt={name}></img>
            <div className="card__content">
                <p className="card__title">{name}</p>
                <p className="card__description">{desc}</p>
            </div>
        </div>
    )
}

export default Card