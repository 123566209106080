import React from 'react'
import { Container, Card } from 'react-bootstrap';
import './UploadCard.css'

function UploadCard({ toolImage, toolTitle, toolDescription, UploadComponent}) {
  return (
    <Container className="d-flex justify-content-center align-items-center main-container">
      <Card className="text-center shadow-lg card-custom">
        <Card.Img variant="top" src={toolImage} className="card-image" />
        <Card.Body className="card-body-custom">
          <Card.Title>{toolTitle}</Card.Title>
          <Card.Text>
            {toolDescription}
          </Card.Text>
          <UploadComponent />
        </Card.Body>
      </Card>
    </Container>
  )
}

export default UploadCard