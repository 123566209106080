import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function DnDReOrderPDFCard({ id, page, pageNumber }) {

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style = {
    touchAction: 'none',
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 999 : 'auto',
    opacity: isDragging ? 0.5 : 1,
    width: '12rem',
    height: '12rem',
    overflow: 'hidden',
    transformOrigin: '0 0',
  };

  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    async function renderThumbnail() {
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;
      setThumbnail(canvas.toDataURL());
    }
    renderThumbnail();
  }, [page])



  return (
    <div ref={setNodeRef} {...attributes} {...listeners} className="card" style={style}>
      {thumbnail && (
        <div className="d-flex align-items-center justify-content-center" style={{ height: '8rem', width: '100%', backgroundColor: '#f8f9fa' }}>
          <img src={thumbnail} alt="PDF Thumbnail" style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
        </div>
      )}
      <div className="card-body p-2 d-flex align-items-center justify-content-center" style={{ height: '4rem' }}>
        <p className="card-text text-center m-0">{pageNumber}</p>
      </div>
    </div>
  );
}

export default DnDReOrderPDFCard