import React, { useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga4';


const MAX_FILES = 5;
const MAX_SIZE = 5 * 1024 * 1024; // 5 MB

const FileUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const [rotateInvoice, setRotateInvoice] = useState(false);
    const [groupBySku, setgroupBySku] = useState(false);
    const [labelUrl, setLabelUrl] = useState(null);
    const [invoiceUrl, setInvoiceUrl] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        let totalSize = files.reduce((sum, file) => sum + file.size, 0);

        if (files.length > MAX_FILES) {
            setError(`You can upload up to ${MAX_FILES} files at a time.`);
            return;
        }

        if (totalSize > MAX_SIZE) {
            setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
            return;
        }

        setSelectedFiles(files);
        setError('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedFiles.length === 0) return;

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('pdfFiles', file);
        });
        formData.append('rotateInvoice', rotateInvoice);
        formData.append('groupBySku', groupBySku);

        setUploading(true);
        setUploadCompleted(false);
        setUploadProgress(0);

        try {
            const response = await axios.post('/pdf/upload', formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                },
                responseType: 'json'
            });
            
            setUploadProgress(90);
            const labelData = response.data.label;
            const invoiceData = response.data.invoice;

            const labelBlob = new Blob([new Uint8Array(labelData.split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
            const invoiceBlob = new Blob([new Uint8Array(invoiceData.split('').map(char => char.charCodeAt(0)))], { type: 'application/pdf' });
            setUploadProgress(100);
            setLabelUrl(window.URL.createObjectURL(labelBlob));
            setInvoiceUrl(window.URL.createObjectURL(invoiceBlob));

            ReactGA.event({
                category: 'User',
                action: 'Flipkart Label and Invoice Cropped'
              });

            setUploading(false);
            setUploadCompleted(true);
        } catch (error) {
            console.error('Failed to upload PDF', error);
            setError('Failed to upload PDF');
            setUploading(false);
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    };

    const currentDateTime = getCurrentDateTime();

    return (
        // <div className="col-md-6 mx-auto mt-5 d-flex flex-column align-items-center">
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            <form onSubmit={handleSubmit} className="">
                <div className="form-group text-center">
                    <div className="custom-file mx-auto">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={handleFileChange}
                            accept="application/pdf"
                            multiple
                            required
                            style={{ cursor: 'pointer' }}
                        />
                        <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
                            {selectedFiles.length > 0
                                ? selectedFiles.map(file => file.name).join(', ')
                                : 'Choose File'}
                        </label>
                    </div>
                </div>
                <div className="form-group form-check d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center mr-5" style={{ cursor: 'pointer' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="groupBySku"
                            checked={groupBySku}
                            onChange={(e) => setgroupBySku(e.target.checked)}
                            style={{ cursor: 'pointer', border: '2px solid #000' }}
                        />
                        <label className="form-check-label ml-2" htmlFor="groupBySku" style={{ cursor: 'pointer' }}>Group By SKU</label>
                    </div>
                    <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="rotateInvoice"
                            checked={rotateInvoice}
                            onChange={(e) => setRotateInvoice(e.target.checked)}
                            style={{ cursor: 'pointer', border: '2px solid #000' }}
                        />
                        <label className="form-check-label ml-2" htmlFor="rotateInvoice" style={{ cursor: 'pointer' }}>Rotate Invoice</label>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }}>Upload</button>
                {(uploading || uploadCompleted) && (
                    <div className="progress mt-3">
                        <div
                            className={`progress-bar ${uploadCompleted ? 'bg-success' : ''}`}
                            role="progressbar"
                            style={{ width: `${uploadProgress}%` }}
                            aria-valuenow={uploadProgress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {uploadProgress}%
                        </div>
                    </div>
                )}
            </form>
            {labelUrl && invoiceUrl && (
                <div className="mt-4 text-center">
                    <a
                        href={labelUrl}
                        className="btn btn-success mr-2"
                        download={`Flipkart_Label_${currentDateTime}.pdf`}
                        style={{ cursor: 'pointer' }}
                    >
                        Download Label
                    </a>
                    <a
                        href={invoiceUrl}
                        className="btn btn-success"
                        download={`Flipkart_Invoice_${currentDateTime}.pdf`}
                        style={{ cursor: 'pointer' }}
                    >
                        Download Invoice
                    </a>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
