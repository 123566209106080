import React from 'react'
import './Loading.css'

function Loading() {
    return (
        <div>
            <div className="circ">
  <div className="load">Working on your File . . . </div>
  <div className="hands"></div>
  <div className="body"></div>
  <div className="head">
    <div className="eye"></div>
  </div>
</div>
        </div>
    )
}

export default Loading