import React, { useState, useEffect } from 'react'
import splitPDFImg from '../assets/Split-PDF.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import UploadCard from '../components/UploadCard'
import Loading from '../assets/Loading'
import { PDFDocument } from 'pdf-lib';
import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';


function SplitUploadComponent() {
  const MAX_FILES = 1;
  const MAX_SIZE = 100 * 1024 * 1024; // 100 MB

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [rangeOption, setRangeOption] = useState('all'); // New state for range option
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const navigate = useNavigate();


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let totalSize = files.reduce((sum, file) => sum + file.size, 0);
    const isAllPdf = files.every(file => file.type === 'application/pdf');

    if (!isAllPdf) {
      setError('All selected files must be PDF.');
      return;
    }

    if (files.length > MAX_FILES) {
      setError(`You can upload up to ${MAX_FILES} files at a time.`);
      return;
    }

    if (totalSize > MAX_SIZE) {
      setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    setSelectedFiles(files);
    setError('');
  };

  const handleRangeChange = (event) => {
    setRangeOption(event.target.value);
  };

  const handleStartPageChange = (event) => {
    setStartPage(event.target.value);
  };

  const handleEndPageChange = (event) => {
    setEndPage(event.target.value);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setUploading(true)
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFiles[0]);
    reader.onload = async () => {
      const pdfBytes = reader.result;
      const pdfDoc = await PDFDocument.load(pdfBytes);

      if (rangeOption === 'custom') {
        const totalPages = pdfDoc.getPageCount();
        if (isNaN(startPage) || isNaN(endPage) || startPage < 1 || endPage > totalPages || startPage > endPage) {
          setError(`Invalid range. Please enter a valid range between 1 and ${totalPages}.`);
          return;
        }
      }

      const zip = new JSZip();

      if (rangeOption === 'all') {
        for (let i = 0; i < pdfDoc.getPageCount(); i++) {
          const newPdfDoc = await PDFDocument.create();
          const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
          newPdfDoc.addPage(copiedPage);
          const newPdfBytes = await newPdfDoc.save();
          zip.file(`split_${i + 1}.pdf`, newPdfBytes);
        }
      } else {
        for (let i = startPage - 1; i < endPage; i++) {
          const newPdfDoc = await PDFDocument.create();
          const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
          newPdfDoc.addPage(copiedPage);
          const newPdfBytes = await newPdfDoc.save();
          zip.file(`split_${i + 1}.pdf`, newPdfBytes);
        }
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const zipUrl = URL.createObjectURL(zipBlob);
      setUploading(false);

      ReactGA.event({
        category: 'User',
        action: 'Splited PDF'
      });


      navigate('/download-file', { state: { link: zipUrl, defaultFilename: 'PDF-Quick-Fix split_pdfs.zip' } });
    };
  };

  return (
    <div>
      {error && <p className="text-danger text-center">{error}</p>}
      {(uploading) && (
        <div>
          <Loading />
        </div>
      )}
      <form onSubmit={handleSubmit} className="rounded w-100">
        <div className="form-group text-center">
          <div className="custom-file mx-auto">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={handleFileChange}
              accept="application/pdf"
              multiple
              required
              style={{ cursor: 'pointer' }}
            />
            <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
              {selectedFiles.length > 0 ? 'File/Files Selected' : 'Choose File'}
            </label>
            {selectedFiles.length > 0 && (
              <div>
                <p className="scrolling-text">
                  {selectedFiles.map(file => file.name).join(', ')}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="form-group text-center">
          <div className="form-check form-check-inline">
            <input
              type="radio"
              id="all"
              name="rangeOption"
              value="all"
              checked={rangeOption === 'all'}
              onChange={handleRangeChange}
              className="form-check-input"
            />
            <label htmlFor="all" className="form-check-label">All</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              id="custom"
              name="rangeOption"
              value="custom"
              checked={rangeOption === 'custom'}
              onChange={handleRangeChange}
              className="form-check-input"
            />
            <label htmlFor="custom" className="form-check-label">Custom Range</label>
          </div>
        </div>

        {rangeOption === 'custom' && (
          <div className="form-group text-center">
            <input
              type="number"
              className="form-control"
              placeholder="Start Page"
              value={startPage}
              onChange={handleStartPageChange}
            />
            <input
              type="number"
              className="form-control mt-2"
              placeholder="End Page"
              value={endPage}
              onChange={handleEndPageChange}
            />
          </div>
        )}

        <div>
          <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
            {uploading ? 'Splitting PDF' : 'Split PDF'}
          </button>
        </div>
      </form>
    </div>
  );
}


function SplitTool() {
  const splitTool = { name: 'Split PDF', desc: 'Use the Split PDF tool to divide your PDF files into separate pages or extract specific page ranges. This tool is perfect for managing large documents and isolating specific sections.', 
    img: splitPDFImg }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Navbar />
      <UploadCard toolImage={splitTool.img} toolTitle={splitTool.name} toolDescription={splitTool.desc} UploadComponent={SplitUploadComponent} />
      <Footer />
    </div>
  )
}

export default SplitTool