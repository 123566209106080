import React from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/logo.png'


const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className='container'>
                <Link className="navbar-brand" to="/">
                    <img src={logo} className="img-fluid logo" alt="Logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Tools
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <Link className="dropdown-item" to="/flipkart-cropper">Flipkart Label Croper</Link>
                                <Link className="dropdown-item" to="/mergepdf">Merge PDF</Link>
                                <Link className="dropdown-item" to="/splitpdf">Split PDF</Link>
                                <Link className="dropdown-item" to="/compresspdf">Compress PDF</Link>
                                <Link className="dropdown-item" to="/reorder-pdf">ReOrder PDF</Link>
                                <Link className="dropdown-item" to="/stamppdf">Stamp PDF</Link>
                                <Link className="dropdown-item" to="/watermarkpdf">Watermark PDF</Link>
                                <Link className="dropdown-item" to="/png-to-pdf">PNG to PDF</Link>
                                <Link className="dropdown-item" to="/pdf-to-docx">PDF to Docs</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
    
};

export default Navbar;
