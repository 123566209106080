import React from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
  return (
    <div>
        <Navbar />
    <div className="container mt-5">
      <h1 className="text-center mb-4">Privacy Policy</h1>
      
      <section className="mb-4">
        <h2 className="h4">Introduction</h2>
        <p>Welcome to <strong>PDF Quick Fix</strong>, a platform providing various PDF tools. Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our website and services.</p>
      </section>
      
      <section className="mb-4">
        <h2 className="h4">Information We Collect</h2>
        <p>We do not collect personal information. The only data we handle are the files you upload for processing, which are automatically deleted after the process is complete.</p>
      </section>
      
      <section className="mb-4">
        <h2 className="h4">How We Use Your Information</h2>
        <ul className="list-unstyled">
          <li><strong>File Processing:</strong> The files you upload are used solely for the purpose of processing as per the tool you choose (e.g., merging, splitting, converting).</li>
          <li><strong>Non-Persistent Storage:</strong> Files are deleted immediately after processing.</li>
        </ul>
      </section>
      
      <section className="mb-4">
        <h2 className="h4">Traffic Tracking</h2>
        <p>We use Google Analytics to track and analyze website traffic. Google Analytics collects information such as your IP address, browser type, and pages visited on our site. This information helps us understand how our website is used and improve our services.</p>
      </section>
      
      <section className="mb-4">
        <h2 className="h4">Security</h2>
        <p>We implement robust security measures to ensure the protection of your files during the upload and processing stages.</p>
      </section>
      
      <section className="mb-4">
        <h2 className="h4">Changes to This Policy</h2>
        <p>We may update this Privacy Policy periodically. Any changes will be reflected on this page, so please review it frequently.</p>
      </section>
      
      <section>
        <h2 className="h4">Contact Us</h2>
        <p>For any questions or concerns regarding this Privacy Policy, please <strong><a href='/contact'>Contact Us</a></strong>.</p>
      </section>
    </div>
    <Footer />
    </div>
  );
}

export default PrivacyPolicy;
