import React, { useState, useEffect } from 'react';
import mergePDFImg from '../assets/Merge-PDF.png';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Container, Card } from 'react-bootstrap';
import '../components/UploadCard.css';
import Loading from '../assets/Loading';
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, pointerWithin, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import DndPDFPreviewCard from '../components/DndPDFPreviewCard';
import { v4 as uuidv4 } from 'uuid';
import { PDFDocument } from 'pdf-lib';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

function MergeTool() {
  const MAX_FILES = 10;
  const MAX_SIZE = 200 * 1024 * 1024; // 200 MB

  const mergeTool = { name: 'Merge PDF', desc: 'Merge multiple PDF files into one with our intuitive drag-and-drop interface. Simply upload your PDFs, arrange them in the desired order, and merge them into a single document with ease.', 
    img: mergePDFImg };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let totalSize = files.reduce((sum, file) => sum + file.size, 0);

    if (files.length > MAX_FILES) {
      setError(`You can upload up to ${MAX_FILES} files at a time.`);
      return;
    }

    if (totalSize > MAX_SIZE) {
      setError(`Total file size should not exceed ${MAX_SIZE / (1024 * 1024)} MB.`);
      return;
    }

    const filesWithId = files.map(file => ({ file, id: uuidv4() }));
    setSelectedFiles(filesWithId);
    setError('');
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (active.id !== over.id) {
      const oldIndex = selectedFiles.findIndex(item => item.id === active.id);
      const newIndex = selectedFiles.findIndex(item => item.id === over.id);
      setSelectedFiles(arrayMove(selectedFiles, oldIndex, newIndex));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  async function handleSubmit(event) {
    event.preventDefault();  // Prevent default form submission
    setUploading(true);  // Optionally, set an uploading state

    try {
      const mergedPdf = await PDFDocument.create();

      for (const item of selectedFiles) {
        const file = item.file;  // Access the file object from the item
        const pdfBytes = await file.arrayBuffer();
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      const mergedPdfFile = await mergedPdf.save();
      const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
      const link = URL.createObjectURL(blob);

      ReactGA.event({
        category: 'User',
        action: 'PDF Merged'
      });

      const defaultFilename = 'PDF-Quick_Fix Merged.pdf';
      navigate('/download-file', { state: { link, defaultFilename } });

    } catch (error) {
      console.error('Error merging PDFs:', error);
      setError('An error occurred while merging PDFs. Please try again.');
    } finally {
      setUploading(false);  // Optionally, reset the uploading state
    }
  }

  const activeItem = selectedFiles.find((item) => item.id === activeId);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Navbar />
      <Container className="d-flex flex-column justify-content-center align-items-center main-container">
        <Card className="text-center shadow-lg card-custom">
          <Card.Img variant="top" src={mergeTool.img} className="card-image" />
          <Card.Body className="card-body-custom">
            <Card.Title>{mergeTool.name}</Card.Title>
            <Card.Text>{mergeTool.desc}</Card.Text>
            <div>
              {error && <p className="text-danger text-center">{error}</p>}
              {uploading && (
                <div>
                  <Loading />
                </div>
              )}
              <form onSubmit={handleSubmit} className="rounded w-100">
                <div className="form-group text-center">
                  <div className="custom-file mx-auto">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={handleFileChange}
                      accept="application/pdf"
                      multiple
                      required
                      style={{ cursor: 'pointer' }}
                    />
                    <label className="custom-file-label" htmlFor="customFile" style={{ cursor: 'pointer' }}>
                      {selectedFiles.length > 0 ? 'File/Files Selected' : 'Choose File'}
                    </label>
                  </div>
                  {selectedFiles.length > 0 && (
                    <DndContext
                      collisionDetection={pointerWithin}
                      sensors={sensors}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                    >
                      <div className="d-flex justify-content-center mt-3 flex-wrap">
                        <SortableContext items={selectedFiles} strategy={horizontalListSortingStrategy}>
                          {selectedFiles.map(({ file, id }) => (
                            <div key={id} className="m-2">
                              <DndPDFPreviewCard file={file} id={id} />
                            </div>
                          ))}
                        </SortableContext>
                      </div>
                      <DragOverlay modifiers={[snapCenterToCursor]} dropAnimation={null} className="drag-overlay">
                        {activeItem ? <DndPDFPreviewCard file={activeItem.file} id={activeItem.id} /> : null}
                      </DragOverlay>
                    </DndContext>
                  )}
                </div>
                <div className="mt-3">
                  <button type="submit" className="btn btn-primary btn-block" style={{ cursor: 'pointer' }} disabled={uploading}>
                    {uploading ? 'Merging...' : 'Merge PDFs'}
                  </button>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}

export default MergeTool;
