import React, { useEffect} from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FileUpload from '../components/FileUpload'
import UploadCard from '../components/UploadCard';
import flipkartLabelCropper from '../assets/Flipkart-Label-Cropper.png'
import ReactGA from 'react-ga4';


function FlipkartCropper() {

  const flipkartCropperTool = { name: 'Flipkart Cropper', desc: 'The Flipkart Cropper tool allows you to easily crop labels and invoices from files provided by Flipkart. It also groups the cropped sections according to their SKU, streamlining your order processing.', 
    img: flipkartLabelCropper }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Navbar />
      <UploadCard toolImage={flipkartCropperTool.img} toolTitle={flipkartCropperTool.name} toolDescription={flipkartCropperTool.desc} UploadComponent={FileUpload} />
      <Footer />
    </div>
  )


  // return (
  //   <div className="App d-flex flex-column min-vh-100">
  //           <Navbar />
  //           <div className="container flex-grow-1 d-flex justify-content-center align-items-center">
  //               <FileUpload />
  //           </div>
  //           <Footer />
  //       </div>
  // )
}

export default FlipkartCropper